import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { IUserProps } from '../common/data/userDummyData';

export interface IAuthContextProps {
  userData: Partial<IUserProps>;
  login: string | null;
  password: string | null;
  setUserData?(...args: unknown[]): unknown;
  setLogin?(login: string): void;
  setPassword?(password: string): void;
}

const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
  children: ReactNode;
}

export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
  // Initialize userData from localStorage
  const [userData, setUserData] = useState<Partial<IUserProps>>(JSON.parse(localStorage.getItem('facit_authUser') || "{}") || {});

  // Initialize login and password from localStorage
  const [login, setLogin] = useState<string | null>(localStorage.getItem('login') || null);
  const [password, setPassword] = useState<string | null>(localStorage.getItem('pwd') || null);

  // Sync userData, login, and password with localStorage
  useEffect(() => {
    localStorage.setItem('facit_authUser', userData?.id && userData?.username ? JSON.stringify(userData) : "{}");
    if (login) {
      localStorage.setItem('login', login);
    }
    if (password) {
      localStorage.setItem('pwd', password);
    }
  }, [userData, login, password]);

  // Provide context values
  const value = useMemo(
    () => ({
      userData,
      login,
      password,
      setUserData,
      setLogin,
      setPassword,
    }),
    [userData, login, password],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
