import axios from 'axios';

const BASIC_API_URL = '/back';
const API_URIS = {
	product: process.env.REACT_APP_NODE_PRODUCT_API_URL,
	product_attribute: process.env.REACT_APP_NODE_PRODUCT_ATTRIBUTE_API_URL,
	product_attribute_value: process.env.REACT_APP_NODE_PRODUCT_ATTRIBUTE_VALUES_API_URL,
	product_variant: process.env.REACT_APP_NODE_PRODUCT_VARIANT_API_URL,
	stock_location: process.env.REACT_APP_NODE_STOCK_LOCATION_API_URL,
	stock_movement: process.env.REACT_APP_NODE_STOCK_MOVEMENT_API_URL,
	stock_warehouse: process.env.REACT_APP_NODE_STOCK_WAREHOUSE_API_URL,
	category: process.env.REACT_APP_NODE_CATEGORY_API_URL,
	brand: process.env.REACT_APP_NODE_BRAND_API_URL,
	attachment: process.env.REACT_APP_NODE_ATTACHMENT_API_URL,
	order: process.env.REACT_APP_NODE_ORDER_API_URL,
	order_line: process.env.REACT_APP_NODE_ORDER_LINE_API_URL,
	caisse: process.env.REACT_APP_NODE_CAISSE_API_URL,
	session: process.env.REACT_APP_NODE_SESSION_API_URL,
	customer: process.env.REACT_APP_NODE_CUSTOMER_API_URL,
	employee: process.env.REACT_APP_NODE_EMPLOYEE_API_URL,
	colis: process.env.REACT_APP_NODE_COLIS_API_URL,
	user: process.env.REACT_APP_NODE_USER_API_URL,
	pointage: process.env.REACT_APP_NODE_POINTAGE_API_URL,
	employeeJob: process.env.REACT_APP_API_EMPLOYEEJOB_API_URI,
	enlevement: process.env.REACT_APP_API_ENLEVEMENT_API_URI,
	EnlevementLine: process.env.REACT_APP_API_ENLEVEMENT_API_URI,
	tracking: process.env.REACT_APP_API_TRACKING_API_URI,
	planning: process.env.REACT_APP_NODE_PLANNING_API_URL,
	livraison: process.env.REACT_APP_NODE_LIVRAISON_API_URL,
	order_movement: process.env.REACT_APP_NODE_ORDER_MOVEMENT_API_URL,
	company: process.env.REACT_APP_NODE_COMPANY_API_URL,
	module: process.env.REACT_APP_NODE_MODULE_API_URL,
	promo: process.env.REACT_APP_NODE_STOCK_PROMO_API_URL
};

const LIMIT = 0;

const handleAxios = (config) => {
	return new Promise((resolve, reject) => {
		axios(config)
			.then(function (response) {
				resolve(response?.data);
			})
			.catch(function (error) {
				if (error?.code !== 'ERR_CANCELED') {
					reject(error);
				} else {
					resolve(null);
				}
			});
	});
};

export const getAPI = async (
	token,
	type,
	fnSuccess = () => { },
	fnError = () => { },
	signal = null,
) => {
	if (!API_URIS[type]) {
		throw new Error(`Invalid API type: ${type}`);
	}
	const url = BASIC_API_URL + API_URIS[type];

	const config = {
		method: 'get',
		url: url,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		signal,
	};

	const res = await handleAxios(config);
	try {
		fnSuccess(res);
		return res;
	} catch (error) {
		fnError(error);
	}
};

export const getAPIById = async (
	token,
	type,
	id,
	fnSuccess = () => { },
	fnError = () => { },
	signal = null,
) => {
	if (!API_URIS[type]) {
		throw new Error(`Invalid API type: ${type}`);
	}
	const url = BASIC_API_URL + API_URIS[type] + '/' + id;

	const config = {
		method: 'get',
		url: url,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		signal,
	};

	// return await handleAxios(config);

	const res = await handleAxios(config);
	try {
		fnSuccess(res);
		return res;
	} catch (error) {
		fnError(error);
	}
};

export const searchAPI = async (
	token,
	type,
	filters,
	page = 0,
	limit = 0,
	fnSuccess = () => { },
	fnError = () => { },
	signal = null,
) => {
	if (!API_URIS[type]) {
		throw new Error(`Invalid API type: ${type}`);
	}
	const url = BASIC_API_URL + API_URIS[type] + '?page=' + page + '&limit=' + limit;

	const config = {
		method: 'patch',
		url: url,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		data: JSON.stringify(filters),
		signal,
	};
	const res = await handleAxios(config);
	try {
		fnSuccess(res);
		return res;
	} catch (error) {
		fnError(error);
	}
};

export const createAPI = async (token, type, newData, fnSuccess = () => { }, fnError = () => { }, signal = null) => {
	if (!API_URIS[type]) {
		throw new Error(`Invalid API type: ${type}`);
	}

	const data = JSON.stringify(newData);
	const url = BASIC_API_URL + API_URIS[type];
	let config = {
		method: 'post',
		url: url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		maxContentLength: Infinity,
		maxBodyLength: Infinity,
		signal,
	};

	if (type === 'attachment') {
		// Create FormData for image upload
		const formData = new FormData();
		Object.keys(newData).forEach((key) => {
			if (key === 'datass') {
				// Convert base64 string back to binary and append it to FormData
				const binaryData = atob(newData[key]);
				const arrayBuffer = new Uint8Array(binaryData.length);
				for (let i = 0; i < binaryData.length; i++) {
					arrayBuffer[i] = binaryData.charCodeAt(i);
				}
				const blob = new Blob([arrayBuffer], { type: newData.mimetype });
				formData.append('datas', blob, newData.name);
			} else {
				formData.append(key, newData[key]);
			}
		});
		config.data = formData;
	} else {
		// Send data as JSON
		config.headers['Content-Type'] = 'application/json';
		config.data = JSON.stringify(newData);
	}

	const res = await handleAxios(config);
	// createTracking(token, {
	// 	action: 'create',
	// 	entityType: type,
	// 	entityId: res?._id || res?.id || res?.data?._id || res?.data?.id,
	// });
	try {
		fnSuccess(res);
		return res;
	} catch (error) {
		fnError(error);
	}
};

export const updateAPI = async (
	token,
	type,
	id,
	newData,
	fnSuccess = () => { },
	fnError = () => { },
	oldData = null,
) => {
	if (!API_URIS[type]) {
		throw new Error(`Invalid API type: ${type}`);
	}

	const data = JSON.stringify(newData);
	const url = BASIC_API_URL + API_URIS[type] + '/' + id;

	const config = {
		method: 'put',
		url: url,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		data: data,
	};
	const res = await handleAxios(config);
	createTracking(token, {
		action: 'update',
		entityType: type,
		entityId: id,
		before: oldData,
		after: newData,
	});
	try {
		fnSuccess(res);
		return res;
	} catch (error) {
		fnError(error);
	}
};

export const updateCustomizedAPI = async (
	token,
	type,
	id,
	newData,
	addedUrl,
	fnSuccess = () => { },
	fnError = () => { },
) => {
	if (!API_URIS[type]) {
		throw new Error(`Invalid API type: ${type}`);
	}

	const data = JSON.stringify(newData);
	const url = BASIC_API_URL + API_URIS[type] + addedUrl + '/' + id;

	const config = {
		method: 'put',
		url: url,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		data: data,
	};
	const res = await handleAxios(config);
	try {
		fnSuccess(res);
		return res;
	} catch (error) {
		fnError(error);
	}
};

export const getCustomizedAPI = async (
	token,
	type,
	addedUrl,
	fnSuccess = () => { },
	fnError = () => { },
	signal = null,
) => {
	if (!API_URIS[type]) {
		throw new Error(`Invalid API type: ${type}`);
	}
	const url = BASIC_API_URL + API_URIS[type] + addedUrl;

	const config = {
		method: 'get',
		url: url,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		signal,
	};

	const res = await handleAxios(config);
	try {
		fnSuccess(res);
		return res;
	} catch (error) {
		fnError(error);
	}
};

export const searchCustomizedAPI = async (
	token,
	type,
	addedUrl,
	filters,
	page = 0,
	limit = 0,
	fnSuccess = () => { },
	fnError = () => { },
	signal = null,
) => {
	if (!API_URIS[type]) {
		throw new Error(`Invalid API type: ${type}`);
	}
	const url = BASIC_API_URL + API_URIS[type] + addedUrl + '?page=' + page + '&limit=' + limit;

	const config = {
		method: 'patch',
		url: url,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		data: JSON.stringify(filters),
		signal,
	};
	const res = await handleAxios(config);
	try {
		fnSuccess(res);
		return res;
	} catch (error) {
		fnError(error);
	}
};
export const searchCustomizedAPI2 = async (
	token,
	type,
	addedUrl,
	filters,
	page = 0,
	limit = 0,
	fnSuccess = () => { },
	fnError = () => { },
	signal = null,
) => {
	if (!API_URIS[type]) {
		throw new Error(`Invalid API type: ${type}`);
	}
	const url = BASIC_API_URL + API_URIS[type] + addedUrl + '&page=' + page + '&limit=' + limit;

	const config = {
		method: 'patch',
		url: url,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		data: JSON.stringify(filters),
		signal,
	};
	const res = await handleAxios(config);
	try {
		fnSuccess(res);
		return res;
	} catch (error) {
		fnError(error);
	}
};



export const searchCustomizedAPIWithDates = async (
	token,
	type,
	addedUrl,
	filters,
	startDate,
	endDate,
	page = 0,
	limit = 0,
	fnSuccess = () => { },
	fnError = () => { },
	signal = null,
) => {
	if (!API_URIS[type]) {
		throw new Error(`Invalid API type: ${type}`);
	}
	const url = BASIC_API_URL + API_URIS[type] + addedUrl + '?page=' + page + '&limit=' + limit + '&startDate=' + startDate + '&endDate=' + endDate;

	const config = {
		method: 'patch',
		url: url,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		data: JSON.stringify(filters),
		signal,
	};
	const res = await handleAxios(config);
	try {
		fnSuccess(res);
		return res;
	} catch (error) {
		fnError(error);
	}
};

export const deleteAPI = async (
	token,
	type,
	id,
	newData,
	fnSuccess = () => { },
	fnError = () => { },
) => {
	if (!API_URIS[type]) {
		throw new Error(`Invalid API type: ${type}`);
	}

	const data = JSON.stringify(newData);
	const url = BASIC_API_URL + API_URIS[type] + '/' + id;

	const config = {
		method: 'delete',
		url: url,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};

	const res = await handleAxios(config);
	createTracking(token, {
		action: 'delete',
		entityType: type,
		entityId: id,
	});
	try {
		fnSuccess(res);
		return res;
	} catch (error) {
		fnError(error);
	}
};

export const postCustomizedAPI = async (
	token,
	type,
	addedUrl,
	newData,
	fnSuccess = () => { },
	fnError = () => { },
	signal = null,
) => {
	if (!API_URIS[type]) {
		throw new Error(`Invalid API type: ${type}`);
	}
	const data = JSON.stringify(newData);
	const url = BASIC_API_URL + API_URIS[type] + addedUrl;

	const config = {
		method: 'post',
		url: url,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		maxContentLength: Infinity,
		maxBodyLength: Infinity,
		data: data,
		signal,
	};

	const res = await handleAxios(config);
	try {
		fnSuccess(res);
		return res;
	} catch (error) {
		fnError(error);
	}
};

export const functionWrapperWithPromise = (fn, ...args) => {
	return new Promise((resolve, reject) => {
		fn(...args, resolve, reject);
	});
};

export const createTracking = async (token, newData = {}) => {
	// Ensure that a valid tracking endpoint exists
	if (!API_URIS['tracking']) {
		throw new Error(`Invalid API type: "tracking"`);
	}
	console.log('traackinnng');
	// Define the tracking API URL
	const url = BASIC_API_URL + API_URIS['tracking'];

	// Configure the request options
	const config = {
		method: 'post',
		url: url,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: JSON.stringify(newData),
	};

	try {
		// Perform the API request
		// const res = await handleAxios(config);
		// return res;
	} catch (error) {
		// Log the error for debugging purposes
		console.error('Error creating tracking entry:', error);
		// You can handle specific errors here if needed (e.g., logging, notifications)
		return null; // Return null or handle error appropriately
	}
};
