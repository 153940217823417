import React, { useContext, useEffect, useRef, useState } from 'react';
import Card, { CardActions, CardBody, CardFooter, CardFooterRight, CardHeader, CardLabel, CardTabItem, CardTitle } from '../../bootstrap/Card';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Icon from '../../icon/Icon';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Label from '../../bootstrap/forms/Label';
import Select from '../../bootstrap/forms/Select';
import Option from '../../bootstrap/Option';
import Input from '../../bootstrap/forms/Input';
import { useFormik } from 'formik';
import { searchJournal } from '../../../odooApi/accountJournal';
import AuthContext from '../../../contexts/authContext';
import moment from 'moment';
import Button from '../../bootstrap/Button';
import Badge from '../../bootstrap/Badge';
import { ImageIcon, getReport } from '../../../common/function/functionsUtils';
import DisplayImage from '../../Activity/DisplayImage';
import { None } from '../../../stories/components/bootstrap/Badge/BadgeUseShadow.stories';
import Spinner from '../../bootstrap/Spinner';
import { searchPaymentByInvoice, updatePaymentJustify, updatePaymentJustifyState, uploadAttachmentPaymentJustify } from '../../../odooApi/paymentJustify';
import COLORS from '../../../common/data/enumColors';
import { searchFactures, updateFacture } from '../../../odooApi/accountMove';
import FactureCard from '../FactureCard';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import ConfirmationPopup from '../../PlanningCalendar/ConfirmationPopup';
import { uploadAttachmentPayment } from '../../../odooApi/accountPayment';
import StatusPopup from '../../PlanningCalendar/StatusPopup';
import { payFacture } from '../../../odooApi/pay';

export default function SuiviPayementCard({ setIsOpen }) {

	const [isLoading, setIsLoading] = useState(false);
	const [invoiceInputSearch, setInvoiceInputSearch] = useState(data?.source?.name);
	const [invoiceIsLoading, setInvoiceIsLoading] = useState(false);
	const [filterInvoiceResult, setFilterInvoiceResult] = useState({});
	const [formState, setFormState] = useState({ invoice_liste: [] });
	const [invoiceDatas, setInvoiceDatas] = useState([]);
	const [openList, setOpenList] = useState(false);
	const [success, setSuccess] = useState(false);
	const [popupImage, setPopupImage] = useState(false);
	const [selectedImage, setSelectedImage] = useState(false);
	const [groups, setGroups] = useState(false);
	const [error, setError] = useState(false);
	const [confirm, setConfirm] = useState(false);
	const [obligation, setObligation] = useState(false)
	const [data, setData] = useState([])

	const { userData } = useContext(AuthContext);
	const fileInputRef = useRef(null);


	const etat_paiement = [
		// {
		// 	id: 'Brouillon',
		// 	title: 'Brouillon',
		// 	color: COLORS.PRIMARY,
		// 	icon: 'ErrorOutline',
		// },
		{
			id: 'Valider',
			title: 'Valider',
			color: COLORS.SUCCESS,
			icon: 'DoneAll',
		},

		{
			id: 'Refuser',
			title: 'Refuser',
			color: COLORS.DANGER,
			icon: 'RemoveDone',
		},
	];

	const getColor = (data) => {
		if (!data || !data.etat) {
			return COLORS.PRIMARY.name;
		}

		const color =
			data.etat === 'Brouillon'
				? COLORS.WARNING.name
				: data.etat === 'Valider'
					? COLORS.INFO.name
					: data.etat === 'Refuser'
						? COLORS.DANGER.name
						: COLORS.SUCCESS.name; // Si aucun état ne correspond, on retourne la couleur par défaut

		return color;
	};


	const handleButtonClick = () => {
		fileInputRef.current.click();
	};

	const handleFileInputChange = (e) => {
		const newAttachments = Array.from(e?.target?.files);
		formik?.setFieldValue('attachements', newAttachments);
	};


	const minAmount = 0;

	const handelBlurAmount = (e) => {
		formik.setFieldValue(
			'montant',
			(e.target.value < minAmount && minAmount) ||
			(e.target.value > data?.montant && data?.montant) ||
			e.target.value,
		);
	};
	const formik = useFormik({
		initialValues: {
			montant: data?.montant,
			date: data?.date || new Date(),
			source: data?.source?.name,
			typePayment: '',
			attachements: [data?.attachment || ''],
			moyen_paiement: data?.moyen_paiement,
			date_paiement: data?.date_paiement || new Date(),
			client: data?.client?.name,
			memo: data?.memo,
			user_id: data?.user_id?.name,
			total: data?.total
		},
		onSubmit: (values) => {
			setSuccess(false);

			if (userData && userData?.id && userData?.password) {
				setIsLoading(true);

				const payInfo = {
					id: data?.id,
					montant: values?.montant,
					memo: values?.memo,
					source: invoiceDatas[0]?.id,
					date: moment(values?.date).format("YYYY-MM-DD"),
					date_paiement: moment(values?.date_paiement).format("YYYY-MM-DD")
				};
				{
					values?.attachements.map(file => {
						if (file.id && file.mimetype && file.name) {

							updatePaymentJustify(
								userData?.password,
								payInfo,

								(results) => {
									setIsLoading(false);
									loadData && loadData();
									setIsOpen(false);
								})

						} else if (file?.type) {
							uploadAttachmentPaymentJustify(
								userData?.password,
								data?.id,
								values?.attachements[0],
								(result) => {
									updatePaymentJustifyState(
										userData?.password,
										{
											id: data?.id,
											attachment: result?.data?.attachment[0],
										},
										() => {
											setIsLoading(false);
											loadData && loadData();
											setIsOpen(false);
										},
									);
								},
							);

						} else {
							updatePaymentJustify(
								userData?.password,
								payInfo,

								(results) => {
									setIsLoading(false);


									loadData && loadData();
									setIsOpen(false);
								})


						}
					})
				}



			}
		},
	});

	const handleFactureChange = (e) => {
		setOpenList(true);
		setInvoiceInputSearch(e?.target?.value);
	};

	const handelSelectInvoice = (item) => {
		setOpenList(false);
		const exist = !!formState?.invoice_liste?.find((id) => id == item?.id);
		if (!exist) {
			formState.invoice_liste = [...formState?.invoice_liste, item?.id];
		}
		setInvoiceDatas([...invoiceDatas, item]);
		setFilterInvoiceResult([]);
	};

	useEffect(() => {
		const abortController = new AbortController();
		const signal = abortController.signal;
		if (invoiceInputSearch === '') {
			setFilterInvoiceResult([]);
		} else
			if (data?.source?.id != '') {
				searchFactures(
					userData?.password,
					[['id', '=', data?.source?.id]],
					({ elements }) => {
						handelSelectInvoice(elements[0])
						setFilterInvoiceResult([]);
						set
						setInvoiceIsLoading(false);
					},
					(error) => {
						setInvoiceIsLoading(true);
					},
					signal,
				);
			}
			else {
				setInvoiceIsLoading(true);
				searchFactures(
					userData?.password,
					[
						'&',
						['type', '=', 'out_invoice'],
						'|',

						['name', 'ilike', invoiceInputSearch],

						'|',
						['nom_client', 'ilike', invoiceInputSearch],

						'|',
						['phone_client', 'ilike', invoiceInputSearch],

						['mobile_client', 'ilike', invoiceInputSearch],
					],
					({ elements }) => {
						setFilterInvoiceResult(elements);
						setInvoiceIsLoading(false);
					},
					(error) => {
						setInvoiceIsLoading(true);
					},
					signal,
				);
			}
		return () => {
			abortController.abort();
		};
	}, [invoiceInputSearch]);


	const handelCreatePayment = (data, groups) => {
		setIsLoading(true);
		if (groups == 'Valider') {
			if (invoiceDatas?.length == 0) {
				setObligation("selectionnez votre facture SVP");
			} else {
				const payInfo = {
					amount: data?.montant,
					journal_id: data?.moyen_paiement?.id,
					invoice_ids: invoiceDatas,
					activity_user_id: userData?.id,
					numero_cheque: data?.memo,
					date_encaissement: moment(data?.date_paiement).format("YYYY-MM-DD")
				};

				payFacture(
					userData?.password,
					payInfo,
					(result) => {
						const info = []
						if (data?.attachment) {
							convertIMG(
								getAttachment(userData?.password, data?.attachment?.id),
								'paiement_' + data?.source?.name,
								(file) => {
									uploadAttachmentPayment(
										userData?.password,
										result?.id,
										file,
										(data) => {

										},
										(error) => {
											setIsLoading(false);
											setError(error);
											formik.setFieldError('attachements', `Erreur : ${error}`);
										},
									);
								},
								(error) => {
									console.error('Erreur :', error);
								},
							);

						}


						updatePaymentJustifyState(
							userData?.password,
							{
								id: data?.id,
								etat: groups,
							},
							() => {
								updateFacture(
									userData?.password,
									{
										id: invoiceDatas[0]?.id,
										accompte: 0,
										state: 'posted',
										invoice_user_id: userData?.id,
										activity_user_id: userData?.id,
									},
									() => {
										setIsLoading(false);
										setIsOpen(false)
										loadData();
									},
								);
							},
							(error) => {
								setError(error);
								setIsLoading(false);
							},
						);
					},
					(error) => {
						setIsLoading(false);
						setIsOpen(false)
						setError(error);
					},
				);
			}

		} else {
			updatePaymentJustifyState(
				userData?.password,
				{
					id: data?.id,
					etat: groups,
				},
				() => {
					updateFacture(
						userData?.password,
						{
							id: invoiceId,
							accompte: 0,
						},
						() => {
							setIsLoading(false);
							setIsOpen(false)
							loadData();
						},
					);
				},
				(error) => {
					setIsLoading(false);
					setError(error);
				},
			);
		}
	};

	return (
		<>
			<Modal isOpen={true} setIsOpen={() => { }} size={'xl'} classNameMd='nv-2'>
				<ModalHeader
					className='px-4'
					setIsOpen={() => {

						setIsOpen(false);
					}}>
					<div className='d-flex justify-content-between w-100'>
						<ModalTitle id='project-edit'>
							Paiement {data?.planning?.name_seq} {' '}
							<span class={`rounded-2 bg-l10-${getColor(data)} text-${getColor(data)}`} >


							</span>
						</ModalTitle>
						<div className='row me-2'>
							{data.etat == 'Brouillon' ? (
								<Dropdown>
									<DropdownToggle>
										<Button
											color={
												etat_paiement?.find(
													(item) =>
														data.etat == item?.id,
												)?.color || 'primary'
											}
											isLight
											icon={
												etat_paiement?.find(
													(item) =>
														data.etat == item?.id,
												)?.icon || 'ErrorOutline'
											}
											size={'sm'}
											isDisable={data.etat == 'Valider'}>
											{data.etat}
										</Button>
									</DropdownToggle>
									<DropdownMenu>
										{etat_paiement?.map((group) => (
											<DropdownItem>
												<Button
													key={group.id}
													color='link'
													icon={group.icon}
													onClick={() => {
														setGroups({
															data: data,
															etat: group?.id,
														});
														setConfirm(true);
													}}>
													{group.title}
												</Button>
											</DropdownItem>
										))}
									</DropdownMenu>
								</Dropdown>
							) : (
								<Badge
									className={`me-2 bg-l10-${data.etat == 'Valider'
										? 'success'
										: 'danger'
										} text-${data.etat == 'Valider'
											? 'success'
											: 'danger'
										} px-3 py-2 me-2 rounded-1`}
									isLight>
									<Icon
										size={'lg'}
										icon={
											etat_paiement?.find(
												(item) =>
													data?.id == item?.etat,
											)?.icon
										}
										color={
											etat_paiement?.find(
												(item) =>
													data?.id == item?.etat,
											)?.color
										}></Icon>
									<span className='fw-semibold fs-6 ms-2 mt-5'>
										{data.etat}
									</span>
								</Badge>
							)}



						</div>
					</div>
				</ModalHeader>

				<ModalBody>
					<Card shadow='none'>
						<div className='row'>
							<div className='col-6'>
								<CardBody>
									<div className='row'>
										<FormGroup className='py-2'>
											<Label>date d'encaissement</Label>
											<Input
												type={'date'}
												/* onChange={handleChange} */
												value={moment(
													formik.values?.date_paiement,
												).format(moment.HTML5_FMT.DATE)}
												name='date_paiement'
												autoComplete='off'
												onChange={formik.handleChange}
												disabled={data?.etat != 'Brouillon'}

											//disabled={true}
											/>
										</FormGroup>
										<FormGroup className='py-2'>
											<Label>Client</Label>
											<Input
												onChange={formik.handleChange}
												value={formik?.values?.client}
												name='client'
												disabled={true}

											/>
										</FormGroup>

										<FormGroup className='py-2'>
											<Label>Montant payé</Label>
											<Input
												onChange={formik.handleChange}
												value={formik?.values?.montant}
												name='montant'
												disabled={data?.etat != 'Brouillon'}

											/>
										</FormGroup>
										<FormGroup className='py-2'>
											<Label>Moyen de paiement</Label>

											<Input
												onChange={formik.handleChange}
												value={formik?.values?.moyen_paiement?.name}
												name="moyen_paiement"
												autoComplete="off"
												disabled={true}

											/>
										</FormGroup>



										<FormGroup className='py-2'>
											<Label>Mémo</Label>
											<Input
												onChange={formik.handleChange}
												value={formik?.values?.memo}
												name="memo"
												autoComplete="off"
												disabled={data?.etat != 'Brouillon'}

											/>
										</FormGroup>

										<Label className='py-2'>Pièce jointe</Label>

										{formik?.values?.attachements.length > 0 ? (
											formik?.values?.attachements.map((file, index) => (
												<div key={index} >
													{file.name != '' ? (
														<Badge
															className='px-3 py-2 rounded-1'
															color={'dark'}
															isLight>

															<span
																className='fs-6 fw-semibold cursor-pointer'
																onClick={() => {
																	setSelectedImage(file);
																	setPopupImage(true);
																}}>
																{file?.name}
															</span>

															<Icon
																icon='Clear'
																size={'md'}
																className='cursor-pointer'
																onClick={() => {

																	formik?.setFieldValue(
																		'attachements',
																		[],
																	);
																}}></Icon>
														</Badge>
													) :
														(
															<>

																<div className='align-items-center  rounded-1 bg-l10-dark border-dashed'>
																	<Icon
																		icon='CloudDownload'
																		size={'2x'}
																		onClick={handleButtonClick}
																		className='cursor-pointer'></Icon>
																	<span>Déposer vos fichiers</span>
																</div>
																<Input
																	type='file'
																	multiple
																	name='attachments'
																	onChange={handleFileInputChange}
																	style={{ display: 'none' }}
																	ref={fileInputRef}
																/>
															</>

														)}
												</div>
											))
										) : (
											<>

												<div className='align-items-center  rounded-1 bg-l10-dark border-dashed'>
													<Icon
														icon='CloudDownload'
														size={'2x'}
														onClick={handleButtonClick}
														className='cursor-pointer'></Icon>
													<span>Déposer vos fichiers</span>
												</div>
												<Input
													type='file'
													multiple
													name='attachments'
													onChange={handleFileInputChange}
													style={{ display: 'none' }}
													ref={fileInputRef}
												/>
											</>
										)}

									</div>
								</CardBody>
							</div>
							<div className='col-6'>
								<CardBody>

									<FormGroup className='py-2'>
										<Label>Date de comptabilisation</Label>
										<Input
											type={'date'}
											// onChange={handleChange}
											value={moment(
												formik.values?.date,
											).format(moment.HTML5_FMT.DATE)}
											name='date'
											autoComplete='off'
											onChange={formik.handleChange}

										/>
									</FormGroup>


									<FormGroup className='py-2'>
										<Label>Créer par</Label>
										<Input
											onChange={formik.handleChange}
											value={formik?.values?.user_id}
											name='user_id'
											disabled={true}

										/>
									</FormGroup>
									<FormGroup className='py-2'>
										<Label>Total</Label>
										<Input
											onChange={formik.handleChange}
											value={formik?.values?.total}
											name='total'
											disabled={true}

										/>
									</FormGroup>


									<FormGroup className='py-2'>
										<Label>Source</Label>
										{!data?.source ? (
											<Input
												onChange={formik.handleChange}
												value={formik?.values?.source}
												name="source"
												autoComplete="off"
												disabled={true}

											/>
										) : (
											<div className='col-lg-12 mb-3'>
												<Input
													placeholder='🔎︎ Rechercher par N°Facture, client ...'
													onChange={handleFactureChange}
													onFocus={handleFactureChange}
													value={invoiceInputSearch}
													name='name'
													autoComplete='off'
												/>
												{openList && invoiceIsLoading ? (
													<div
														id='myInputautocomplete-list'
														className='autocomplete-items'>
														<div className='body'>
															<div tag={'button'}>
																<Spinner isSmall isGrow />{' '}
																<Spinner isSmall isGrow />{' '}
																<Spinner isSmall isGrow />
															</div>
														</div>
													</div>
												) : (
													!!filterInvoiceResult?.length && (
														<div
															id='myInputautocomplete-list'
															className='autocomplete-items'>
															<div className='body'>
																{filterInvoiceResult?.map((item, idx) => (
																	<div
																		className='d-flex g-1'
																		key={idx}
																		onClick={() => {
																			handelSelectInvoice(item);
																		}}>
																		<div className='w-auto'>
																			<small className={` px-2 `}>
																				<Icon
																					icon='Info'
																					className='me-1'
																					size={'lg'}
																				/>
																				<span className='text-dark'>
																					{item?.name}
																				</span>
																			</small>
																		</div>
																		<div className='w-auto'>
																			<small className={` px-2 `}>
																				<Icon
																					icon='Person'
																					className='me-1'
																					size={'lg'}
																				/>
																				<span className='text-dark'>
																					{item?.nom_client}
																				</span>
																			</small>
																		</div>
																		{(!!item?.phone_client ||
																			!!item?.mobile_client) && (
																				<div className='w-auto '>
																					<small
																						className={`  px-2   `}>
																						<Icon
																							icon='LocalPhone'
																							className='me-1'
																							size={'lg'}
																						/>
																						<span className='text-dark'>
																							{
																								item?.phone_client
																							}{' '}
																							{
																								item?.mobile_client
																							}
																						</span>
																					</small>
																				</div>
																			)}

																		<div className='w-auto '>
																			<small
																				className={`  px-2   `}>
																				<Icon
																					icon='Today'
																					className='me-1'
																					size={'lg'}
																				/>
																				<span className='text-dark'>
																					{moment(
																						item?.create_date,
																					).format(
																						'DD/MM/yyyy',
																					)}
																				</span>
																			</small>
																		</div>
																	</div>
																))}
															</div>
														</div>
													)
												)}
											</div>
										)}

										<div className='row mt-3'>
											{!!invoiceDatas?.length &&
												invoiceDatas?.map((invoice, idx) => {
													return (
														<div className='col-lg-12 col-md-6 col-sm-12' key={idx}>
															<Card isCompact>
																<CardHeader>
																	<CardLabel>
																		<Label>Facture - {invoice?.state}</Label>
																	</CardLabel>
																</CardHeader>
																<CardBody>
																	<FactureCard
																		card={invoice}
																	//loadFactures={loadDevis}
																	/>
																</CardBody>
															</Card>
														</div>
													);
												})}
										</div>

									</FormGroup>

								</CardBody>

							</div>
						</div>
						<CardFooter>
							<CardFooterRight>

								{data?.etat == "Brouillon" &&
									<Button
										color='primary'

										icon={!isLoading && 'Save'}
										type='submit'
										isDisable={isLoading}
										onClick={formik.handleSubmit}>
										{isLoading && <Spinner isSmall inButton />}
										{isLoading ? 'En cours' : 'Enregistrer'}
									</Button>}


							</CardFooterRight>

						</CardFooter>
					</Card>


				</ModalBody>




			</Modal >
			{!!popupImage && <DisplayImage setSelectedImage={setPopupImage} file={selectedImage} />}
			{!!confirm && (
				<ConfirmationPopup
					handleConfirm={() => handelCreatePayment(groups?.data, groups?.etat)}
					setOpen={setConfirm}
					title={groups?.etat + ' ce paiement ?'}
				/>
			)}
			{!!obligation && (
				<StatusPopup
					icon='info'
					color='info'
					title={obligation}
					// label={obligation}
					setOpen={setObligation}
				/>
			)}
		</>
	);
}
