import React from 'react'
import useDeviceScreen from '../../hooks/useDeviceScreen';
import Navigation from '../../layout/Navigation/Navigation';
import CommonHeaderRight from '../common/Headers/CommonHeaderRight';
import Header, { HeaderLeft } from '../../layout/Header/Header';

export const comptaMenu = {

    Clients: {
        id: 'clients',
        text: 'Clients',
        path: '/comptabilite/ventes',
        className: 'bg-l10-primary text-primary',
        icon: 'FormatIndentDecrease',
        subMenu: {
            Factures: {
                id: 'factures_Clients',
                text: 'Factures',
                path: '/comptabilite/ventes/factures',
                icon: 'BootstrapFill',
                subMenu: null,
            },
            Paiements: {
                id: 'paiements_Clients',
                text: 'Paiements',
                path: '/comptabilite/ventes/paiements',
                icon: 'CustomStorybook',
                subMenu: null,
            },
            Avoirs: {
                id: 'avoirs_Clients',
                text: 'Avoirs',
                path: '/comptabilite/ventes/avoirs',
                icon: 'CheckBox',
                subMenu: null,
            },
        },
    },
    Fournisseurs: {
        id: 'fournisseurs',
        text: 'Fournisseurs',
        path: '/comptabilite/achats',
        icon: 'FormatIndentIncrease',
        subMenu: {
            Factures: {
                id: 'factures_Fournisseurs',
                text: 'Factures',
                path: '/comptabilite/achats/factures',
                icon: 'BootstrapFill',
                subMenu: null,
            },
            Paiements: {
                id: 'paiements_Fournisseurs',
                text: 'Paiements',
                path: '/comptabilite/achats/paiements',
                icon: 'CustomStorybook',
                subMenu: null,
            },
            Avoirs: {
                id: 'avoirs_Fournisseurs',
                text: 'Avoirs',
                path: '/comptabilite/achats/avoirs',
                icon: 'CheckBox',
                subMenu: null,
            },
        },
    },

    Configuration: {
        id: 'configuration',
        text: 'Configuration',
        path: '/comptabilite/configuration',
        icon: 'BuildCircle',
        subMenu: {
            PlanComptable: {
                id: 'planComptable',
                text: 'Plan Comptable',
                path: '/comptabilite/planComptable',
                // icon: 'BootstrapFill',
                subMenu: null,
            },
            Taxes: {
                id: 'taxes',
                text: 'Taxes',
                path: '/comptabilite/taxes',
                // icon: 'CustomStorybook',
                subMenu: null,
            },
            Journaux: {
                id: 'journaux',
                text: 'Journaux',
                path: '/comptabilite/journaux',
                //icon: 'CheckBox',
                subMenu: null,
            },
            EcritureComptable: {
                id: 'ecritureComptable',
                text: 'Ecriture Comptable',
                path: '/comptabilite/ecrituresComptables',
                //icon: 'CheckBox',
                subMenu: null,
            },
            Bilan: {
                id: 'bilan',
                text: 'Bilan',
                path: '/comptabilite/bilan',
                //  icon: 'CheckBox',
                subMenu: null,
            },
            GrandLivre: {
                id: 'grandLivre',
                text: 'Grand Livre',
                path: '/comptabilite/grandLivre',
                //icon: 'CheckBox',
                subMenu: null,
            },
        },
    }
}
export default function HeaderClient() {
    const { width } = useDeviceScreen();

    return (
        <Header>
            <HeaderLeft>
                <Navigation
                    menu={comptaMenu}

                    id='summary-top-menu'
                    horizontal={
                        !!width && width >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
                    }
                />
            </HeaderLeft>
            <CommonHeaderRight />
        </Header>
    );
}
